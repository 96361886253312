import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import store from './LgcCloneWizard/store/store';
import LgcModal from './LgcModal';
import LgcCloneWizard from './LgcCloneWizard/LgcCloneWizard';

const LgcClone = () => {
  const [plasmidId, setPlasmidId ] = useState(false);
  const [elementName, setElementName ] = useState(false);
  const [elementId, setElementId ] = useState(false);
  const [sequencesIds, setSequencesIds ] = useState(false);
  const [sectionId, setSectionId ] = useState(false);
  const [cloningId, setCloningId ] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onCloseWizard = (_) => {
    setModalOpen(false);
  }

  const onOpenWizard = (e) => {
    const {cloningId, name, elementId, plasmidId, sequencesIds, sectionId} = e.detail;
    setPlasmidId(plasmidId);
    setSequencesIds(sequencesIds);
    setSectionId(sectionId);
    setElementName(name)
    setElementId(elementId)
    setCloningId(cloningId)
    setModalOpen(true);
  }

  useEffect(() => {
    window.addEventListener("OpenCloneWizard", onOpenWizard);

    return () => {
      window.removeEventListener("OpenCloneWizard", onOpenWizard);
    };
  }, [onOpenWizard]);

  return(
    <div className="LgcClone">
      { modalOpen &&
        <Provider store={store}>
          <LgcModal
            style={"lgc-modal-dialog lgc-modal-dialog--lg"}
          >
            <LgcCloneWizard
              plasmidId={plasmidId}
              sequencesIds={sequencesIds}
              sectionId={sectionId}
              name={elementName}
              elementId={elementId}
              cloningId={cloningId}
              onCancel={onCloseWizard}
            />
          </LgcModal>
        </Provider>
      }
    </div>
  )
}

export default LgcClone;
