
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { MethodEnum } from '../lgcCloningMethod';

import { setStep, selectAllSteps, selectCurrentStep } from './slices/steps/stepperSlice';
import { createSettingsThunk ,resetStettingsState, updateSettingsThunk} from './slices/settingsSlice';
import { selectCloningObject } from './slices/cloneSlice';
import { updateVectorThunk } from './slices/vectorSlice';
import { selectFragments, setCurrentFragmentId, updateFragmentsThunk } from './slices/fragmentsSlice';
import { performCloneThunk } from './slices/assembleSlice';
import LgcStepper from '../LgcStepper/LgcStepper';
import LgcInteractivePanel from '../LgcInteractivePanel/LgcInteractivePanel';
import LgcCloneSettings from './steps/LgcCloneSettings';
import LgcCloneVector from './steps/LgcCloneVector';
import LgcResultsStep from './steps/LgcResultsStep';
import LgcFragments from './steps/LgcFragments';
import LgcPerformClone from './steps/LgcPerformClone';
import LgcModal from '../LgcModal';

export default function LgcCloneWizard(props) {
  const dispatch = useDispatch();
  const steps = useSelector(selectAllSteps);
  const enzymes = useSelector((state) => state.vector.enzymes);
  const currentStepId = useSelector((state) => state.stepper.currentStepId);
  const disableButtons = useSelector((state) => state.stepper.disableButtons);
  const currentStep = useSelector(selectCurrentStep);
  const cloningObject = useSelector(selectCloningObject);
  const fragments = useSelector(selectFragments);
  const [method, setMethod] = useState(0);
  const [insertToPlasmid, setInsertToPlasmid] = useState(true);
  const [linMethod, setLinMethod] = useState(0);
  const [instructions, setInstructions] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(()=>{
    createClone();
  },[]);

  const onModalOk = (withCollection) => {
    onCloseWizard();
    setModalOpen(false);
    axios.get(`/api/v1/molecular_biology_cloning/${cloningObject.id}/output_files_urls?inventory=${withCollection}`)
    .then(response => {
      const payload = {
            name: cloningObject.name,
            item:{
              files: response.data.files,
              inventory_items: response.data.inventory_items,
              method: MethodEnum[cloningObject.cloning_method],
              base_vector: {url: cloningObject.base_plasmid.url,name: cloningObject.base_plasmid.name},
              linearization_method: cloningObject.linearization_method,
              restriction_enzyme_names: cloningObject.restriction_enzyme_names.map((name) => {return JSON.parse(name).valueForGibson}),
              molecular_biology_fragments: fragments.map((item) => ({
                title: item.title,
                url: item.url,
                reverse: item.reverse,
                entire_fragment: item.entire_fragment
              }))
            }
      }
      const ocw = new CustomEvent(`UpdateElement_${props.elementId}`, { detail: JSON.stringify(payload)});
      dispatchEvent(ocw);
    })

  }

  const onCloseWizard = () => {
    dispatch(resetStettingsState());
    props.onCancel();
  }
  const handleInstructions = (value) => {
    setInstructions(value);
  }

  const renderCurrentStep = () => {
    switch (currentStepId) {
      case 1:
        return (
          <LgcCloneSettings
            elementId={props.elementId}
            method={method}
            insertToPlasmid={insertToPlasmid}
            setMethod={setMethod}
            setInsertToPlasmid={setInsertToPlasmid}
            handleInstructions = {handleInstructions}
          />
        );
      case 2:
        return(
          <LgcCloneVector
            setLinMethod={setLinMethod}
            linMethod={linMethod}
            handleInstructions = {handleInstructions}
          />
        );
      case 3:
        return (
          <LgcFragments
            handleInstructions = {handleInstructions}
          />
        );
      case 4:
        return (
          <LgcPerformClone
            handleInstructions = {handleInstructions}/>);
      case 5:
        return (
          <LgcResultsStep
            modal= {LgcModal}
            isModalOpen={isModalOpen}
            onModalOk={onModalOk}
            onModalCancel={() => setModalOpen(false)}
            handleInstructions = {handleInstructions}
            />
          );
      default:
        console.log(`step ${currentStepId} not implemented`);
        break;
    }
  }

  const handleNext = () => {
    switch (currentStepId) {
      case 1:
        updateCloneWithSettings();
        break;
      case 2:
        updateCloneWithVector();
        break;
      case 3:
        updateFragments();
        break;
      case 4:
        assemble();
        break;
      case 5:
        finish();
        break;
      default:
        console.log(`step ${currentStepId} not implemented`);
        onCloseWizard();
        break;
    }
  }

  const finish = () => {
    setModalOpen(true);
  }
  const handlePrev = () => {
    switch (currentStepId) {
      case 1:
        onCloseWizard();
        break;
      default:
        dispatch(setStep(currentStepId - 1));
        break;
    }
  }
  const handleSetStep = (id) => {
    dispatch(setStep(id));
  }

  const updateCloneWithSettings = () => {
    const payload = {
      id: cloningObject.id,
      name: cloningObject.name,
      description: cloningObject.description,
      cloning_method: parseInt(method)
    }
    dispatch(updateSettingsThunk(payload));
  }

  const createClone = () => {
    const payload = {
      id: props.cloningId,
      element_id: props.elementId,
      insert_to_plasmid: insertToPlasmid,
      base_plasmid_id: parseInt(props.plasmidId),
      sequences_ids: props.sequencesIds,
      name: props.name
    }
    dispatch(createSettingsThunk(payload));
  }

  const updateCloneWithVector = () => {
    if(enzymes.length){
      const payload = {
        id: cloningObject.id,
        linearization_method: parseInt(linMethod),
        restriction_enzyme_names: enzymes.map((enzyme) => {return JSON.stringify(enzyme)})
      };

      dispatch(updateVectorThunk(payload));

    }else{
      Flash.error("Choose restriction enzymes");
    }
  }

  const updateFragments = () => {
    if(fragments.some(fragment =>
      !!fragment.entire_fragment == 0 &&
      (
        fragment.seq_start_index == null ||
        typeof fragment.seq_start_index != 'number' ||
        fragment.seq_end_index == null ||
        typeof fragment.seq_end_index != 'number'
      )
    )){
      Flash.error("Invalid fragment start/end value");
    }else{
      const payload = {
        id: cloningObject.id,
        molecular_biology_fragments: fragments.map((molecular_biology_fragment) => {
          return{
            cloning_id: molecular_biology_fragment.cloning_id,
            created_at: molecular_biology_fragment.created_at,
            deleted_at: molecular_biology_fragment.deleted_at,
            entire_fragment: molecular_biology_fragment.entire_fragment,
            id: molecular_biology_fragment.id,
            position: molecular_biology_fragment.position,
            reverse: molecular_biology_fragment.reverse,
            seq_end_index: molecular_biology_fragment.seq_end_index,
            seq_start_index: molecular_biology_fragment.seq_start_index,
            sequence_id: molecular_biology_fragment.sequence_id,
            updated_at: molecular_biology_fragment.updated_at
          }
        })
      };
      dispatch(updateFragmentsThunk(payload));
      dispatch(setCurrentFragmentId(null));

    }

  }


  const assemble = () => {
    const payload = {
      id: cloningObject.id
    };
    dispatch(performCloneThunk(payload));
  }

  const renderNextButtonText = () => {
    switch (currentStepId) {
      case 4:
        return "Assemble"
        break;
      case 5:
        return "Add To Experiment"
        break;
      default:
        return "Next"
        break;
    }
  }

  return (
    <div className='lgc-clone-wizard'>
      <header className='lgc-clone-wizard__header'>
        <h3>Cloning {'>'} { currentStep.title }</h3>
      </header>

      <section className='lgc-clone-wizard__body overflow-y'>
        <LgcInteractivePanel
          Stepper={LgcStepper}
          steps={steps}
          onSetStep={handleSetStep}
          currentStepId={currentStepId}
          Instructions= {instructions}/>
        <>{renderCurrentStep()}</>
      </section>

      <footer className='lgc-clone-wizard__footer'>
        <button className="lg-button white" onClick={handlePrev} disabled={disableButtons} >
          { currentStepId == 1 ? 'Cancel' : 'Back' }
        </button>

        <button className="lg-button df" disabled={disableButtons} onClick={handleNext} >
          { renderNextButtonText() }
        </button>
      </footer>
    </div>
  )
}

