import React from 'react'

export default function LgcDismissButton({ handler, buttonClassName }) {
  return (
    <button
      className={`lgc-dismiss-button ${buttonClassName}`}
      data-lg-id='dismiss_button'
      onClick={handler}
    >
      <i className="fa fa-times" />
    </button>
  );
}
