import React from 'react';

export default function LgcModal({children, style}) {
  return (
    <div className="lgc-modal-overlay">
      <div className={style}>
        {children}
      </div>
    </div>
  )
}
