import React from 'react';
import { useSelector } from 'react-redux';
import LgcToast from './LgcToasterQ/LgcToast';

export default function LgcToasterQ() {
  const toasts = useSelector(state => state.toasts);

  return (
    <div className='lgc-toaster__queue'>
      { toasts.map(item => (
        <LgcToast
          key={item.id}
          opts={item}
        />
      )) }
    </div>
  )
}
