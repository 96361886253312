import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import LgcDismissButton from '../../atomic/LgcDismissButton';
import { dismiss } from '../slices/toastsSlice';

export default function LgcToast({opts}) {
  const toasterRef = useRef(null);
  const dispatch = useDispatch();
  const toastLevel = opts.level || 'default';
  const dismissible = opts.dismissible === undefined ? true : opts.dismissible;
  let dismissTimer;

  useEffect(() => {
    let timer;

    if (opts.timeout) {
      timer = setTimeout(handleDismiss, opts.timeout);
    }

    return () => {
      if (timer) { clearTimeout(timer) };
    };
  }, [dispatch, opts.timeout, opts.id]);

  useEffect(() => {
    const animateInTimer = setTimeout(() => {
      toasterRef.current.classList.remove('lgc-toaster__toast--preparing'); // animate in
    }, 100);

    return () => {
      clearTimeout(animateInTimer);
      if (dismissTimer) { clearTimeout(dismissTimer) }
    };
  }, []);

  const handleDismiss = () => {
    toasterRef.current.classList.add('lgc-toaster__toast--dismissing'); // animate out

    dismissTimer = setTimeout(() => { dispatch(dismiss(opts.id)) }, 300);
  }

  return (
    <div
      ref={toasterRef}
      className={`lgc-toaster__toast lgc-toaster__toast--${toastLevel} lgc-toaster__toast--preparing`}
      data-lg-id='page_notification'
      onClick={handleDismiss}
    >
      <span dangerouslySetInnerHTML={{ __html: opts.content }} />

      { dismissible && <LgcDismissButton handler={handleDismiss} buttonClassName={'lgc-toaster__toast-dismiss-button'}/> }
    </div>
  );
}
